// set bootstrap SASS variables
// custom colors
$cirrax-orange: #E36829;
$brand-primary: $cirrax-orange;
$btn-default-color: #fff;
$btn-default-bg: lighten($cirrax-orange, 10%);
$btn-default-border: darken($btn-default-bg, 5%);
$input-border-focus: lighten($cirrax-orange, 10%);

$navbar-height: 70px;
$navbar-padding-vertical: 20px;
$navbar-bottom-height: 25px;
$navbar-default-border: $cirrax_orange;

// heading font and color
$headings-font-family: "allerregular";
$headings-color: lighten($cirrax-orange, 10%);

// import bootstrap
@import "bootstrap";

// page layouts body width
@media screen and (min-width: $screen-md-min) {
    .layout-text {
        padding-left: 170px;
    }
}

// cirrax logo font
@font-face {
    font-family: 'allerregular';
    src: url('/fonts/aller_std_rg-webfont.woff2') format('woff2'),
         url('/fonts/aller_std_rg-webfont.woff') format('woff'),
         url('/fonts/aller_std_rg-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

.cirrax-logo {
  height: $navbar-height - ($navbar-padding-vertical*2);
}

.inline-image {
    vertical-align: baseline;
}

h1 .inline-image {
    height: 50px;
}

.menu-icon svg { width: 18px; height: 16px; }

.github-icon-svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}

.content {
  margin: 0 auto;
  width: 700px;
}

// vertical space for rows
.margin-bottom-xs {
    margin-bottom: ceil($line-height-computed / 4);
}
.margin-bottom-sm {
    margin-bottom: ceil($line-height-computed / 2);
}
.margin-bottom-md {
    margin-bottom: $line-height-computed;
}
.margin-bottom-sm {
    margin-bottom: ceil($line-height-computed * 2);
}

// top navigation styles

// larger navbar border
.navbar-fixed-top {
    border-bottom-width: 3px;
}

// reset larger navbar for small devices
$collapsed-navbar-height: 50px;
$collapsed-navbar-padding-vertical: (($collapsed-navbar-height - $line-height-computed) / 2);
@media screen and (max-width: $grid-float-breakpoint) {
    .cirrax-logo {
        height: $collapsed-navbar-height - ($collapsed-navbar-padding-vertical*2);
    }
    .navbar {
        min-height: $collapsed-navbar-height;
    }
    .navbar-nav {
        margin: ($collapsed-navbar-padding-vertical / 2) (-$navbar-padding-horizontal);
    }
    .navbar-toggle {
        $element-height: 34px;
        margin-top: (($collapsed-navbar-height - $element-height) / 2);
        margin-bottom: (($collapsed-navbar-height - $element-height) / 2);

    }
    .navbar-brand {
        height: $collapsed-navbar-height;
        padding-top: $collapsed-navbar-padding-vertical;
        padding-bottom: $collapsed-navbar-padding-vertical;
    }
}

// menu
#main-nav .navbar-nav {
    a {
        color: $navbar-default-link-color;
        padding: 0;
        padding-bottom: 4px;
        &.active {
            border-bottom: 2px solid $navbar-default-link-color;
        }
        &:hover,
        &:focus {
            color: $navbar-default-link-hover-color;
            border-bottom: 2px solid $navbar-default-link-hover-color;
            text-decoration: none;
        }
    }
    > li > span.nav-link {
        padding-bottom: 20px;
        cursor: pointer;
    }
    @media screen and (min-width: $grid-float-breakpoint) {
        margin-top: 25px;

        > li > span.nav-link::before {
            color: $navbar-default-link-color;
            content: "|";
        }
        > li:first-child > span.nav-link::before {
            content: initial;
        }
    }
    li {
        font-family: allerregular;
        font-size: 130%;
        font-weight: normal;
        color: $navbar-default-link-color;
        padding: 4px 2px;

        ul {
            visibility: hidden;
            list-style: none;
            display: inline;
            background-color: $navbar-default-bg;
            padding-left: 0;
            margin-left: auto;
            margin-right: auto;
            li {
                padding: 5px;
                font-size: 100%;
                font-weight: normal;
            }

            a {
                padding: 2px 0;
            }
            @media screen and (min-width: $grid-float-breakpoint) {
                position: absolute;
                top: 45px;
                left: 0;
                right: 0;
                width: 150px;
                border: 3px solid $cirrax-orange;
                border-top: none;
            }
            @media screen and (max-width: $grid-float-breakpoint) {
                position: fixed;
                left: 150px;
                top: $collapsed-navbar-height + ($collapsed-navbar-padding-vertical / 2);
                z-index: 10;
                li {
                    font-size: 100%;
                    padding: 4px 2px;
                }
            }
        }

        &:hover ul,
        &:focus ul {
            visibility: visible;
        }
    }
}

.navbar-buttons {
    @media screen and (min-width: $grid-float-breakpoint) {
        @include navbar-vertical-align(30px);
    }
}

.navbar-collapsed-buttons {
    border: none;
    margin: 0;
    float: right;
    @media screen and (min-width: $grid-float-breakpoint) {
        display: none;
    }
}
// bottom navbar
#site-footer .navbar {
    min-height: $navbar-bottom-height;
}

// offset body because of fixed top navbar
body {
    padding-top: $navbar-height + 20px;
    padding-bottom: $navbar-bottom-height + 20px;
}

// opensource project images
img.osimg {
    width: 160px;
    padding-right: 40px;
}

img.wideimg {
    width: 100%;
}

// sidebar

// align with heading
.sidebar {
    margin-top: $line-height-computed;
}

// styles for maintenance table
.table-maintenance {
    .column-date {
        width: 130px;
    }
}

// styles for cloud price calculator
#cloud-calculator {
    .slider-handle, .slider-selection {
        background: lighten($cirrax-orange, 15%);
    }

    // slider should fill the column
    .slider.slider-horizontal {
        width: 100%;
    }

    #slider-timespan .tooltip-main {
        z-index: auto;
    }

    // less padding for value input
    .value-input {
        padding: 5px;
    }

    // table column widths
    table {
        table-layout: fixed;
    }
    td, th {
        display: table-cell;
        float: none;
        position: static;
    }
    .col-type {
        width: 60%;
    }
    .col-slider {
        width: 40%;
    }
    .col-text-input {
        width: 60px;
    }
    .col-unit {
        width: 35px;
    }
    .col-times, .col-equals {
        width: 10px;
    }
    .col-price-factor {
        width: 65px;
    }
    .col-price {
        width: 65px;
    }
}

#cloud-calculator, #cloud-rate {
    // Text in tooltips nicht zentrieren
    .tooltip-inner {
        text-align: left;
    }
}

// jumbotron button adjustments (front-page)
.jumbotron .btn {
    margin-bottom: 5px;
}
